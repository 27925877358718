// First we need to import axios.js
import axios from 'axios';
// Next we make an 'instance' of it
const instance = axios.create({
// .. where we make our configurations
    // baseURL: 'http://127.0.0.1:5000/api/'
    baseURL: 'https://lint-dev.com/api/'
});


export default instance;
