import React, { useEffect, useCallback, useRef } from "react";
import { Switch, Route, Link, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import AuthService from "./services/auth";
import Home from "./components/home";
import Profile from "./components/profile";
import BoardUser from "./components/user-component";
import { AccountsComponent } from "./accounts/components/accounts";
import { AccountComponent } from "./accounts/components/account";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import IconButton from "@material-ui/core/IconButton";
import DashboardIcon from "@material-ui/icons/Dashboard";
import CategoryIcon from "@material-ui/icons/Category";
import LockIcon from "@material-ui/icons/Lock";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import LinkIcon from "@material-ui/icons/Link";
import Plaid from "./components/plaid-component";
import userService from "./services/user-service";
import { AccountTransactionsComponent } from "./accounts/components/account-transactions";
import { TransactionComponent } from "./transaction/components/transaction";
import { CategoriesComponent } from "./categories/components/categories";
import { CategoryComponent } from "./categories/components/category";
import { CategoryTransactionsComponent } from "./categories/components/category-transactions";
import { LoginComponent } from "./login/components/login";
import { TermsOfServiceComponent } from "./privacy/terms-of-service";
import { RegisterComponent } from "./login/components/register";
import { ForgotPasswordComponent } from "./login/components/forgot-password";
import {
  Banner,
  Button,
  ButtonGroup,
  Tooltip,
  Modal,
  BodyText,
  Tag
} from "@servicetitan/design-system";
import IdleTimer from "./IdleTimer";
import { useHistory } from "react-router-dom";
import { PrivacyComponent } from "./privacy/privacy";

export default function App() {
  const [currentUser, setCurrentUser] = React.useState();
  const [showLogoutModal, setShowLogoutModal] = React.useState(false);
  const history = useHistory();
  const [seconds, setSeconds] = React.useState(15);
  const [keepWorking, setKeepWorking] = React.useState(false);
  const increment = useRef(null);
  const [openBanner, setOpenBanner] = React.useState(false);

  const logOut = (event) => {
    AuthService.logout();
    history.push("/login");
    setCurrentUser(null);
    setShowLogoutModal(false);
  };

  const updateCount = () => {
    increment.current = setInterval(() => {
      setSeconds((seconds) => seconds - 1);
    }, 1000);
    if (seconds < 0) {
      clearInterval(increment.current);
    }
  };

  useEffect(() => {
    setKeepWorking(false);
    const user = AuthService.getCurrentUser();
    if (user) {
      setCurrentUser(user);
      const idleTimer = new IdleTimer({
        timeout: 120,
        grace: 15,
        onTimeout: () => {
          setShowLogoutModal(true);
          updateCount();
        },
        onExpired: () => {
          logOut();
          setOpenBanner(true);
        },
      });
      return () => {
        clearInterval(increment.current);
        idleTimer.cleanUp();
      };
    }
  }, [keepWorking]);

  return (
    <div>
      <Modal
        open={showLogoutModal}
        focusTrapOptions={{ disabled: true }}
        onClose={() => alert("closing")}
        title="Done Working?"
        footer={
          <ButtonGroup>
            <Button onClick={() => logOut()}>Log Out</Button>
            <Button
              onClick={() => {
                setSeconds(15);
                clearInterval(increment.current);
                setShowLogoutModal(false);
                setKeepWorking(true);
              }}
              primary
            >
              Keep Working
            </Button>
          </ButtonGroup>
        }
        portal={false}
      >
        <BodyText>
          Looks like you're not actively working, would you like to log out or
          keep working? You have {seconds} seconds to decide.
        </BodyText>
      </Modal>
      <nav className="navbar navbar-expand navbar-light bg-light">
        {currentUser ? (
          <Link to={"/home"} className="navbar-brand">
            lint
          </Link>
        ) : (
          <Link to={"/login"} className="navbar-brand">
            lint
          </Link>
        )}
        {currentUser && (
          <div className="navbar-nav mr-auto">
            <li className="nav-item">
              <Tooltip title="summary">
                <IconButton href={"/home"} className="nav-link">
                  <DashboardIcon></DashboardIcon>
                </IconButton>
              </Tooltip>
            </li>
            <div className="nav-item">
              <li className="nav-item">
                <Tooltip title="accounts">
                  <IconButton href={"/accounts"} className="nav-link">
                    <AccountBalanceIcon></AccountBalanceIcon>
                  </IconButton>
                </Tooltip>
              </li>
            </div>
            <div className="nav-item">
              <li className="nav-item">
                <Tooltip title="categories">
                  <IconButton href={"/categories"} className="nav-link">
                    <CategoryIcon></CategoryIcon>
                  </IconButton>
                </Tooltip>
              </li>
            </div>
            <li className="nav-item">
              <Tooltip title="integration">
                <IconButton href={"/plaid"} className="nav-link">
                  <LinkIcon></LinkIcon>
                </IconButton>
              </Tooltip>
            </li>
            <li className="nav-item">
              <Tooltip title="profile">
                <IconButton href={"/user"} className="nav-link">
                  <AccountBoxIcon></AccountBoxIcon>
                </IconButton>
              </Tooltip>
            </li>
          </div>
        )}
        {currentUser ? (
          <div className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link to={"/profile"} className="nav-link">
                {currentUser.username}
              </Link>
            </li>
            <li className="nav-item">
              <Tooltip text="Report Bug" portal>
                <Button
                  fill="subtle"
                  iconName="bug_report"
                  href="https://airtable.com/shryQYbR3QtXQHVA7"
                  target="_blank"
                />
              </Tooltip>
            </li>
            <li className="nav-item">
              <Tooltip text="Logout" portal>
                <Button
                  fill="subtle"
                  iconName="lock"
                  href={"/login"}
                  onClick={logOut}
                />
              </Tooltip>
            </li>
          </div>
        ) : (
          // <div className="navbar-nav ml-auto">
          //   <li className="nav-item">
          //     <Link to={"/login"} className="nav-link">
          //       Login
          //     </Link>
          //   </li>

          //   <li className="nav-item">
          //     <Link to={"/register"} className="nav-link">
          //       Sign Up
          //     </Link>
          //   </li>
          // </div>
          < div/>
        )}
      </nav>
      {openBanner && (
        <Banner onClose={() => { setOpenBanner(false)}} title="You were logged out due to inactivity." />
      )}

      <div className="container mt-3">
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              return currentUser ? (
                <Redirect to="/home" />
              ) : (
                <Redirect to="/login" />
              );
            }}
          />
          <Route exact path={["/login"]} component={LoginComponent} />
          <Route exact path={["/home"]} component={Home} />
          <Route exact path={["/terms-of-service"]} component={TermsOfServiceComponent} />
          <Route exact path={["/privacy"]} component={PrivacyComponent} />
          <Route exact path={["/accounts"]} component={AccountsComponent} />
          <Route exact path={["/categories"]} component={CategoriesComponent} />
          <Route exact path={["/category/:id"]} component={CategoryComponent} />
          <Route exact path={["/plaid"]} component={Plaid} />
          <Route
            exact
            path={["/accounts/:id"]}
            component={AccountTransactionsComponent}
          />
          <Route exact path={["/account/:id"]} component={AccountComponent} />
          <Route
            exact
            path={["/categories/:id"]}
            component={CategoryTransactionsComponent}
          />
          <Route
            exact
            path={["/account/:id/transaction/:id"]}
            component={TransactionComponent}
          />
          <Route
            exact
            path={["/category/:id/transaction/:id"]}
            component={TransactionComponent}
          />
          <Route exact path="/register" component={RegisterComponent} />
          <Route exact path="/profile" component={Profile} />
          <Route path="/user" component={BoardUser} />
          <Route
            path="/forgot-password/:token"
            component={ForgotPasswordComponent}
          />
        </Switch>
      </div>
    </div>
  );
}
